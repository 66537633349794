<div
  luxLayout="column"
  luxFlex
  class="spot-long-form-text"
  *ngIf="testRows && testRows.length"
  [formGroup]="samplesAssociationForm">
  <div id="association-table-container" luxLayout="column">
    <div id="association-table" luxLayout="column" luxFlex="100%">
      <div class="sample-association-header" luxFlex luxLayout="row" luxLayoutAlign="start start" luxLayoutGap="8px">
        <div class="select-sample-message" luxFlex="245px" luxLayoutAlign="start end" luxFill>
          <div *ngIf="samplesAssociationForm.invalid">
            <span class="spot-form__field-error" role="alert">
              <svg data-cy="needs-association-icon" class="spot-icon needs-association-icon" aria-labelledby="title">
                <title>{{ 'LABELS.ATTENTION' | translate }}</title>
                <use xlink:href="/assets/icons/spot_icons.svg#alert-notification"></use>
              </svg>
              <span class="spot-form__field-error-text">{{ 'LABELS.SELECT_SAMPLE_ASSOCIATION' | translate }}</span>
            </span>
          </div>
        </div>
        <div
          luxFlex="120px"
          luxFill
          luxLayout="column"
          luxLayoutGap="3px"
          data-cy="sample-header"
          class="sample-header"
          [ngClass]="{ 'inactive-sample-type': sample.sampleTypeStatus === SampleTypeStatus.INACTIVE }"
          luxLayoutAlign="end center"
          title="{{ sample.label }}"
          *ngFor="let sample of samples">
          <div
            luxLayout="row wrap"
            *ngIf="sample?.collectionMethod || sample?.draw || sample?.temperature"
            class="sample-attributes">
            <ng-container *ngIf="sample?.collectionMethod">
              <div class="sample-attribute">{{ sample?.collectionMethod.name }}</div>
            </ng-container>

            <ng-container *ngIf="sample?.draw">
              <div class="sample-attribute">{{ sample?.draw.name }}</div>
            </ng-container>

            <ng-container *ngIf="sample?.temperature">
              <div class="sample-attribute">{{ sample?.temperature.name }}</div>
            </ng-container>
          </div>
          <span *ngIf="sample.sampleTypeStatus === SampleTypeStatus.INACTIVE" class="inactive-sample-type-label">{{
            'ERRORS_AND_FEEDBACK.INACTIVE' | translate
          }}</span>
          <ng-container *ngIf="sample.shortCode; else noSampleShortcode">
            <div class="sample-label" luxLayoutAlign="center end">
              {{ sample.shortCode }}
            </div>
          </ng-container>
          <ng-template #noSampleShortcode>
            <div class="sample-label" luxLayoutAlign="center end">
              {{ sample.label }}
            </div>
          </ng-template>
        </div>
      </div>
      <div class="sample-association-body" luxFlex luxLayout="column">
        <ng-container *ngFor="let testRow of testRows; let i = index">
          <div
            luxFlex
            luxLayoutGap="8px"
            luxLayout="row"
            class="test-row"
            [ngClass]="{
              standalone: testRow.isAssayRow && !testRow.parentId,
              panel: !testRow.isAssayRow,
              invalid: displayNeedsAssociationAlert(testRow)
            }">
            <div luxFlex="230px">
              <span luxFlex="20px" *ngIf="testRow.isAssayRow">
                <svg
                  #needAssociationIcon
                  data-cy="needs-association-icon"
                  *ngIf="displayNeedsAssociationAlert(testRow)"
                  class="spot-icon needs-association-icon"
                  aria-labelledby="title">
                  <title>{{ 'LABELS.ATTENTION' | translate }}</title>
                  <use xlink:href="/assets/icons/spot_icons.svg#alert-notification"></use>
                </svg>
              </span>
              <span class="test-identifier" luxLayout="column">
                <ng-container *ngIf="testRow?.isAssayRow; else panelTestCode">
                  <span class="test-code">
                    {{ testRow.code }}
                  </span>
                  <span class="test-name">{{ testRow.name }}</span>
                </ng-container>

                <ng-template #panelTestCode>
                  <span class="test-code">{{ testRow.code }}</span>
                  <span class="test-name">{{ testRow.name }}</span>
                </ng-template>
              </span>
            </div>

            <div
              *ngFor="let sample of samples; let i = index"
              luxFlex="120px"
              class="sample-selection"
              [ngClass]="{ 'inactive-sample-type': sample.sampleTypeStatus === SampleTypeStatus.INACTIVE }"
              (mousedown)="catchAndPreventScroll($event, sample.id + '_' + testRow?.contextualizedTestId + '-control')"
              luxLayoutAlign="center center">
              <div class="cl-radio-button-wrapper" *ngIf="testRow?.isAssayRow; else panelCheckboxes">
                <label
                  class="cl-radio-button"
                  [attr.data-sample-code]="sample.code"
                  [attr.data-test-id]="testRow?.contextualizedTestId"
                  [attr.data-cy]="sample.shortCode + '_' + testRow?.code + '-control'">
                  <input
                    (click)="changeAssociation(testRow?.code, sample.id, $event)"
                    type="radio"
                    id="{{ sample.id + '_' + testRow?.contextualizedTestId + '-control' }}"
                    value="{{ sample.id }}"
                    formControlName="{{ testRow?.contextualizedTestId }}" />
                </label>
              </div>

              <ng-template #panelCheckboxes>
                <label
                  [attr.data-sample-code]="sample.code"
                  [attr.data-panel-id]="testRow.contextualizedTestId"
                  [attr.data-cy]="sample.shortCode + '_' + testRow?.code + '-control'"
                  class="spot-form__checkbox spot-form__checkbox-indeterminate cl-checkbox-label-wrapper">
                  <input
                    (click)="toggleAll(testRow.contextualizedTestId, sample.id)"
                    type="checkbox"
                    class="spot-form__checkbox-input" />
                  <span tabindex="-1" class="spot-form__checkbox-inner">
                    <span class="spot-form__checkbox-visual">
                      <svg
                        *ngIf="isAll(testRow.contextualizedTestId, sample.id)"
                        class="spot-icon spot-form__checkbox-checked-icon"
                        style="display: inline-block"
                        aria-labelledby="title">
                        <title>checkmark</title>
                        <use xlink:href="/assets/icons/spot_icons.svg#checkmark"></use>
                      </svg>
                      <svg
                        *ngIf="isSome(testRow.contextualizedTestId, sample.id)"
                        class="spot-icon spot-form__checkbox-indeterminate-icon"
                        style="display: inline-block"
                        aria-labelledby="title">
                        <title>checkbox-indeterminate</title>
                        <use xlink:href="/assets/icons/spot_icons.svg#checkbox-indeterminate"></use>
                      </svg>
                    </span>
                    <span class="spot-form__checkbox-label"></span>
                  </span>
                </label>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
