<div
  luxLayout="row"
  luxLayoutAlign="end center"
  class="cancel-reason-selector-wrapper"
  data-cy="accession-cancel-reason-selector">
  <label [attr.title]="disabled ? ('ERRORS_AND_FEEDBACK.CANNOT_CANCEL_ACCESSION' | translate) : ''" [class.disabled]="disabled ? true : false"
    >{{ 'LABELS.CANCEL_ACCESSION' | translate
    }}<select [attr.title]="disabled ? ('ERRORS_AND_FEEDBACK.CANNOT_CANCEL_ACCESSION' | translate) : ''" (change)="updateCancelReason($event)" [disabled]="disabled">
      <option></option>
      <ng-container *ngFor="let cancelReason of cancelReasons">
        <option value="{{ cancelReason.code }}" [selected]="cancelReasonCodeSelected === cancelReason.code">
          {{ cancelReason.description }}
        </option>
      </ng-container>
    </select>
  </label>
</div>
