<cl-field-wrapper
  #componentWrapper
  id="animalTypeInput"
  class="autocomplete-wrapper"
  (keydown.escape)="handleEscape($event)"
  [loading]="searching"
  [required]="true"
  (lostFocus)="handleFocusOut($event)"
  [errors]="errors"
  [searcher]="true"
  [label]="'LABELS.PATIENT_ANIMALTYPE' | translate">
  <input
    #input
    clMoveNextOnMissing
    clSelectSelf
    [placeholder]="placeholder"
    [(ngModel)]="displayValue"
    [ngClass]="{
      'inactive-animal-type':
        AnimalTypeInputValue.isAnimalTypeInputValue(value) && value.status === AnimalTypeStatus.INACTIVE
    }"
    (keydown.backspace)="handleBackspace()"
    (blur)="onChange(value)"
    (keydown.arrowDown)="onArrowDown($event)"
    (keydown.arrowUp)="onArrowUp($event)"
    (keydown.tab)="onTabDown($event)"
    class="spot-form__input"
    spellcheck="false"
    autocomplete="new-item" />
  <div [hidden]="!displayedData?.length" class="search-results-wrapper">
    <button
      luxLayout="row"
      class="result-list-item"
      data-cy="autocomplete-item"
      type="button"
      (click)="handleClick($event, match)"
      *ngFor="let match of displayedData; let index = index; let first = first"
      [class.selection]="first">
      <span class="content">
        <span *ngIf="match.shortCode" class="animal-type-match-shortCode">
          {{ match.shortCode }}
        </span>
        <span class="full-animal-type">
          <span>
            {{ match.groupType }}
          </span>
          <span class="animal-type-match-divider" *ngIf="match?.group">&#x025B8;</span>
          <span *ngIf="match?.group">
            {{ match.group }}
          </span>
          <span class="animal-type-match-divider" *ngIf="match?.subGroup">&#x025B8;</span>
          <span *ngIf="match?.subGroup">
            {{ match.subGroup }}
          </span>
        </span>
      </span>
    </button>
  </div>
</cl-field-wrapper>
