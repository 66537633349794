<div
  id="orderValidationDetails"
  class="order-validation-modal">
  <!-- MODAL TRIGGER -->
  <a
    (click)="open()"
    class="spot-link"
    #orderValidationDetailsLink
    id="orderValidationDetailsLink"
    href="javascript:void(0);">
    {{ 'ERRORS_AND_FEEDBACK.VIEW_DETAILS' | translate }}
  </a>
  <!-- /MODAL TRIGGER -->

  <lux-modal-container
    luxFill
    data-cy="order-validation-details-modal"
    #modalContent
    [showHeader]="false"
    (modalClose)="close()"
    (viewChange)="handleModalViewChange()"
    [modalId]="'orderValidationDetailsModal'"
    [modalWidth]="'85%'"
    [modalHeight]="'70%'"
    [visible]="_visible">
    <!-- HEADER -->
    <div luxLayout="row" class="spot-modal__header order-validation-modal-header row">
      <div class="spot-modal__titles" luxLayout="40%">
        <h2 class="spot-modal__title" *ngIf="!showSampleAssociations">
          {{ 'LABELS.ORDER_VALIDATION_DETAILS' | translate }}
        </h2>
        <h2 class="spot-modal__title" *ngIf="showSampleAssociations">
          {{ 'LABELS.SAMPLE_ASSOCIATION_HEADER' | translate }}
        </h2>
      </div>

      <a
        *ngIf="showSamplesAssociationToggle()"
        class="sample-association-btn"
        (click)="handleModalViewChange()"
        (keydown.enter)="handleViewToggleKeyDown($event)"
        [class.no-nav]="!_visible"
        href="javascript:void(0)">
        <span *ngIf="!showSampleAssociations">{{ 'LABELS.SAMPLE_ASSOCIATION_HEADER' | translate }}</span>
        <span *ngIf="showSampleAssociations">{{ 'LABELS.ORDER_VALIDATION_DETAILS' | translate }}</span>
      </a>

      <cl-button
        #closeButton
        [class.no-nav]="!_visible"
        (click)="close()"
        [type]="'icon-only'"
        [icon]="'close'"
        class="spot-modal__header-cancel-button cl-modal-cancel cl-modal-close">
      </cl-button>
    </div>
    <!-- /HEADER -->

    <div luxLayout="row" class="order-validation-modal-content" (dblclick)="onDoubleClick($event)">
      <div *ngIf="!showSampleAssociations" class="container" luxLayout="column" luxFlex="100%">
        <div luxLayout="row" luxLayoutAlign="space-around center" id="legend">
          <span class="key leopard-box">{{ 'LABELS.VALIDATE_MANUALLY' | translate }}</span>
          <span class="key alert"
            >{{ 'LABELS.TEST_BLOCKED' | translate }}
            <span class="hot-key-instructions"
              >({{ 'HOT_KEY_INSTRUCTIONS.EDIT' | translate }} )</span
            ></span
          >
          <span class="key positive">{{ 'LABELS.VALIDATION_CONFIRMED' | translate }}</span>
        </div>
        <div
          id="detail-items"
          [class.edit-accession]="isExistingAccession && !hasOVResponse()"
          *ngIf="orderedItems !== missingInformationGlyph">
          <ng-container *ngFor="let item of uncanceledTests">
            <div class="detail-item-container">
              <span
                class="detail-item top-level-item selected-short-code-{{ item.testCode }} {{ item.displayType }} {{
                  item.reviewDisplayType
                }}"
                [class.new-addition]="item?._links"
                [attr.data-cy]="'selected-short-code-' + item.testCode"
                luxLayoutAlign="start center">
                <span class="review-indicators">
                  <span class="review-indicator review-indicator-alert"></span>
                  <span class="review-indicator review-indicator-positive"></span>
                </span>
                <span class="short-code-selected">{{ item.testCode }}</span
                >&nbsp;-&nbsp;{{ item.name }}

                <ng-container>
                  <label luxLayoutAlign="start center">
                    <cl-checkbox
                      #toggle
                      *ngIf="displayDeclineToggle(item)"
                      [class.no-nav]="!_visible"
                      data-cy="declineToggle"
                      class="decline-toggle"
                      (updateParent)="toggleBlock(item, $event)"
                      [toggle]="true"
                      [title]="'LABELS.RUN_TEST' | translate"
                      [value]="!isBlocked(item)"></cl-checkbox>

                    <cl-checkbox
                      #toggle
                      *ngIf="displayManualBlockToggle(item)"
                      [class.no-nav]="!_visible"
                      data-cy="manualBlockToggle"
                      class="manual-block-toggle"
                      (updateParent)="toggleBlock(item, $event, true)"
                      [toggle]="true"
                      [title]="'LABELS.RUN_TEST' | translate"
                      [value]="!isBlocked(item)"></cl-checkbox>
                  </label>
                </ng-container>

                <svg
                  *ngIf="item['_itemLoading'] && !displayManualBlockToggle(item)"
                  class="spot-icon spot-loading-spinner"
                  aria-labelledby="title">
                  <title>loading...</title>
                  <use xlink:href="../../assets/icons/spot_icons.svg#spinner"></use>
                </svg>
              </span>

              <div *ngIf="item['assays'] && item['assays'].length > 0" style="padding-left: 20px" luxLayout="row wrap">
                <span
                  [attr.data-cy]="'selected-short-code-' + assay.testCode"
                  *ngFor="let assay of item['assays']"
                  luxLayout="row"
                  luxLayoutAlign="start center"
                  class="detail-item mid-level-item selected-short-code-{{ assay.testCode }} {{ assay.displayType }}">
                  <span class="short-code-selected">{{ assay.testCode }}</span
                  >&nbsp;-&nbsp;{{ assay.name }}

                  <ng-container>
                    <label luxLayoutAlign="start center">
                      <cl-checkbox
                        #toggle
                        *ngIf="displayDeclineToggle(assay)"
                        [class.no-nav]="!_visible"
                        [class]="assay.contextualizedTestId"
                        data-cy="declineToggle"
                        class="decline-toggle"
                        (updateParent)="toggleBlock(assay, $event)"
                        [toggle]="true"
                        [title]="'LABELS.RUN_TEST' | translate"
                        [value]="!isBlocked(assay)"></cl-checkbox>

                      <cl-checkbox
                        #toggle
                        *ngIf="displayManualBlockToggle(assay)"
                        [class.no-nav]="!_visible"
                        [class]="assay.contextualizedTestId"
                        data-cy="manualBlockToggle"
                        class="manual-block-toggle"
                        (updateParent)="toggleBlock(assay, $event, true)"
                        [toggle]="true"
                        [title]="'LABELS.RUN_TEST' | translate"
                        [value]="!isBlocked(assay)"></cl-checkbox>
                    </label>
                  </ng-container>
                  <svg
                    *ngIf="assay['_itemLoading'] && !displayManualBlockToggle(assay)"
                    class="spot-icon spot-loading-spinner"
                    aria-labelledby="title">
                    <title>loading...</title>
                    <use xlink:href="../../assets/icons/spot_icons.svg#spinner"></use>
                  </svg>
                </span>
              </div>

              <div *ngIf="item['panels'] && item['panels'].length > 0" style="padding-left: 20px">
                <div *ngFor="let panel of item['panels']">
                  <span
                    luxLayoutAlign="start center"
                    class="detail-item mid-level-item selected-short-code-{{ panel.testCode }} {{ panel.displayType }} {{ panel.reviewDisplayType }}">
                    <span class="review-indicators">
                      <span class="review-indicator-alert"></span>
                      <span class="review-indicator-positive"></span>
                    </span>
                    <span class="short-code-selected">{{ panel.testCode }}</span
                    >&nbsp;-&nbsp;{{ panel.name }}
                  </span>
                  <div
                    *ngIf="panel['assays'] && panel['assays'].length > 0"
                    style="padding-left: 20px"
                    luxLayout="row wrap">
                    <span
                      *ngFor="let assay of panel['assays']"
                      luxLayoutAlign="start center"
                      class="detail-item bottom-level-item selected-short-code-{{ assay.testCode }} {{
                        assay.displayType
                      }}">
                      <span class="short-code-selected">{{ assay.testCode }}</span
                      >&nbsp;-&nbsp;{{ assay.name }}

                      <ng-container>
                        <label luxLayoutAlign="start center">
                          <cl-checkbox
                            #toggle
                            *ngIf="displayDeclineToggle(assay)"
                            [class.no-nav]="!_visible"
                            [class]="assay.contextualizedTestId"
                            data-cy="declineToggle"
                            class="decline-toggle"
                            (updateParent)="toggleBlock(assay, $event)"
                            [toggle]="true"
                            [title]="'LABELS.RUN_TEST' | translate"
                            [value]="!isBlocked(assay)"></cl-checkbox>

                          <cl-checkbox
                            #toggle
                            *ngIf="displayManualBlockToggle(assay)"
                            [class.no-nav]="!_visible"
                            [class]="assay.contextualizedTestId"
                            data-cy="manualBlockToggle"
                            class="manual-block-toggle"
                            (updateParent)="toggleBlock(assay, $event, true)"
                            [toggle]="true"
                            [title]="'LABELS.RUN_TEST' | translate"
                            [value]="!isBlocked(assay)"></cl-checkbox>
                        </label>
                      </ng-container>
                      <svg
                        *ngIf="assay['_itemLoading'] && !displayManualBlockToggle(assay)"
                        class="spot-icon spot-loading-spinner"
                        aria-labelledby="title">
                        <title>loading...</title>
                        <use xlink:href="../../assets/icons/spot_icons.svg#spinner"></use>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="showSampleAssociations" class="container" luxLayout="column" luxFlex="100%">
        <cl-sample-association
          #sampleAssociations
          id="sampleAssociations"
          [samples]="notRemovedSamples"
          [tests]="uncanceledTests"></cl-sample-association>
      </div>
    </div>

    <!-- FOOTER -->
    <div
      *ngIf="showSampleAssociations"
      class="spot-modal__footer cl-modal-footer order-validation-modal-footer"
      luxLayout="row"
      luxLayoutAlign="end center"
      luxLayoutGap="20px">
      <cl-button
        #primaryButton
        [class.no-nav]="!_visible"
        (click)="close(true)"
        [type]="'primary'"
        [icon]="'save'"
        class="cl-modal-primary"
        >{{ 'BUTTONS.CONFIRM' | translate }}
      </cl-button>
    </div>
    <!-- /FOOTER -->
  </lux-modal-container>
</div>
