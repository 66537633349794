import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CvaConnectorComponent, StringUtil } from '../../../utils';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateFormService } from '../date-form.service';
import { DateSegmentInputService } from './date-segment-input.service';

@Component({
  selector: 'cl-date-segment-input',
  templateUrl: './date-segment-input.component.html',
  styleUrls: ['./date-segment-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateSegmentInputComponent,
      multi: true,
    },
  ],
})
export class DateSegmentInputComponent extends CvaConnectorComponent {
  @Input()
  max: number;

  @Input()
  min: number;

  @Input()
  placeholder: string;

  @Input()
  first: boolean;

  @Input()
  last: boolean;

  @Output()
  pastedData: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('input', { static: true })
  input!: ElementRef;

  @HostListener('paste', ['$event'])
  onPaste($event): void {
    const clipboardData = $event.clipboardData.getData('Text');

    if (StringUtil.hasSpecial(clipboardData)) {
      this.pastedData.emit(clipboardData);
    }
  }

  constructor(
    injector: Injector,
    private dateFormService: DateFormService,
    private dateSegmentInputService: DateSegmentInputService
  ) {
    super(injector);
  }

  // Update input value with padded string based on entered value
  handleBlur() {
    if (!this.control.value) {
      return;
    }

    let updatedValue = null;

    if (this.formControlName === 'date' || this.formControlName === 'month') {
      updatedValue = this.dateFormService.getPaddedValue(this.control.value);

      this.control.setValue(updatedValue);
    } else {
      updatedValue = this.dateFormService.inferFullYear(this.control.value);

      this.control.setValue(updatedValue);
    }
  }

  // Select input value on focus
  handleFocus() {
    if (this.control.value) {
      this.input.nativeElement.select();
    }
  }

  handleKeydown($event) {
    const dateSegmentHotkeys = ['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'Backspace', 'Delete', ' '];

    // Navigate to next segment on any special character
    if (StringUtil.hasSpecial($event.key)) {
      this.stopBubble($event);
      this.navigateNext();
      return;
    }

    if ($event.key.toUpperCase() === 'V' && $event.ctrlKey) {
      return;
    }

    const recognizedEventKey = dateSegmentHotkeys.indexOf($event.key) > -1 ? $event.key : null;

    if (recognizedEventKey) {
      switch (recognizedEventKey) {
        case 'ArrowDown':
          // Instead of navigating, decrement the field value
          if ($event.altKey) {
            this.stopBubble($event);
            this.decrementValue();
          }
          break;
        case 'ArrowLeft':
        case 'Backspace':
          // Navigate to the previous field when cursor position is at index 0 in the current field
          if ((!this.control.value || this.isCursorAtStart()) && !document.getSelection().toString()) {
            this.stopBubble($event);
            this.navigatePrevious();
          }
          break;
        case 'ArrowRight':
          // Navigate to the next field when cursor position is at the last index in the current field
          if ((!this.control.value || this.isCursorAtEnd()) && !document.getSelection().toString()) {
            this.stopBubble($event);
            this.navigateNext();
          }
          break;
        case 'ArrowUp':
          // Instead of navigating, increment the field value
          if ($event.altKey) {
            this.stopBubble($event);
            this.incrementValue();
          }
          break;
        case 'Delete':
        case ' ':
          // Clear value on space bar event
          this.stopBubble($event);
          this.control.setValue(null);
          break;
        default:
          return true;
      }
    } else {
      // Prevent non-number input values
      if (isNaN($event.key)) {
        $event.preventDefault();
        return $event;
      }
    }
  }

  decrementValue() {
    const updatedValue = this.dateSegmentInputService.decrementValue(this.control.value, this.min, this.max);
    this.control.setValue(updatedValue);
  }

  incrementValue() {
    const updatedValue = this.dateSegmentInputService.incrementValue(this.control.value, this.min, this.max);
    this.control.setValue(updatedValue);
  }

  isCursorAtEnd() {
    const valueLength = this.control.value.length;
    return valueLength && this.input.nativeElement.selectionEnd === valueLength;
  }

  isCursorAtStart() {
    const valueLength = this.control.value.length;
    return valueLength && this.input.nativeElement.selectionStart === 0;
  }

  navigateNext(force?: boolean) {
    if (!this.last || force) {
      const keyboardEvent: KeyboardEvent = new KeyboardEvent('keydown', {
        bubbles: true,
        key: 'Tab',
      });
      this.input.nativeElement.dispatchEvent(keyboardEvent);
    }
  }

  navigatePrevious() {
    if (!this.first) {
      const keyboardEvent: KeyboardEvent = new KeyboardEvent('keydown', {
        bubbles: true,
        key: 'Tab',
        shiftKey: true,
      });
      this.input.nativeElement.dispatchEvent(keyboardEvent);
    }
  }

  stopBubble($event: KeyboardEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }
}
