import { RouterModule, Routes } from '@angular/router';
import { orderEntryRoutes } from './order-entry/order-entry.router';
import { UnavailableComponent } from './unavailable/unavailable.component';

export const ROUTES: Routes = [
  { path: 'unavailable', component: UnavailableComponent },
  { path: '', redirectTo: 'order-entry/NOV', pathMatch: 'full' },
  ...orderEntryRoutes
];

export const routing = RouterModule.forRoot(ROUTES);
