import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export class Translatable {
  text: string;
  args?: any[];
}

@Directive({ selector: '[clFieldLabel]' })
export class FieldLabelDirective {
  @Input() clFieldLabel;
}

@Component({ template: '' })
/* tslint:disable */
export class Field {
  /* tslint:enable */
  get parentField() {
    return this;
  }

  @Input() hover: boolean;
  @Input() focus: boolean;
  @Input() error: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() microText: string | Translatable | (string | Translatable)[];
  @Input() errorValidations: (string | Translatable)[];
  @Input() label: string;
  @Input() fieldClassName: string = 'cl-field-wrapper';
  @Input() errorClassName: string = 'spot-form--error';
  @Input() hoverClassName: string = 'spot-form--hover';
  @Input() focusClassName: string = 'spot-form--focus';
  @Input() disabledClassName: string = 'spot-form--disabled';
  @Input() formControlName: string;

  _onChange: Function = Function.prototype;
  _onTouched: Function = Function.prototype;

  static Providers(ref): any[] {
    return [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ref),
        multi: true,
      },
      {
        provide: Field,
        useExisting: forwardRef(() => ref),
      },
    ];
  }

  static getErrorValidations(errors: any, alwaysAllowRequired = false) {
    if (!errors) {
      return [];
    }
    if (errors.errors) {
      errors = errors.errors;
    }
    let result = [];
    let p, error, requiredIndex;

    for (p in errors) {
      if (p) {
        if (!alwaysAllowRequired && p === '_required') {
          requiredIndex = result.length;
        }

        error = errors[p];
        // any string is now considered an error
        // (even empty -- however empty strings are not displayed)
        if (error && (error.message || typeof error.message === 'string')) {
          result.push(error.message);
        }
      }
    }

    if (!alwaysAllowRequired && result.length > 1) {
      result.splice(requiredIndex, 1);
    }

    // remove empty results
    result = result.filter((v) => v);

    return result;
  }

  constructor(public element: ElementRef) {}

  blurInput(action?) {}

  focusInput(action?) {}

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  registerOnChange(_onChange: Function) {
    this._onChange = _onChange;
  }
  registerOnTouched(_onTouched: Function) {
    this._onTouched = _onTouched;
  }
}

@Component({
  selector: 'cl-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: Field.Providers(FieldComponent),
  encapsulation: ViewEncapsulation.None,
})
export class FieldComponent extends Field implements OnInit {
  @ContentChild(FieldLabelDirective)
  fieldLabelContent: FieldLabelDirective;

  constructor(public element: ElementRef, public translate: TranslateService) {
    super(element);
  }
  ngOnInit() {
    this.element.nativeElement['clField'] = this.parentField;
  }

  getClassName() {
    const parentField = this.parentField;
    this.errorValidations = parentField.errorValidations;

    return `${parentField.fieldClassName || ''}
         ${
           ((parentField.error || (parentField.errorValidations && parentField.errorValidations.length)) &&
             parentField.errorClassName) ||
           ''
         }
         ${(parentField.focus && parentField.focusClassName) || ''}
         ${(parentField.hover && parentField.hoverClassName) || ''}
         ${(parentField.disabled && parentField.disabledClassName) || ''} ${
      parentField.required === true ? 'cl-required' : ''
    }`;
  }

  _getMicroTexts(): (string | Translatable)[] {
    let result = [];

    if (this.microText) {
      result = result.concat(Array.isArray(this.microText) ? this.microText : [this.microText]);
    }

    return result;
  }

  /*
   * REQUIRED BY IMPLEMENTATION
   */

  @Input()
  focusInput(action?): void {
    this.parentField.focusInput(action);
  }

  @Input()
  blurInput(action?): void {
    this.parentField.blurInput(action);
  }
}
