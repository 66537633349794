<cl-field-wrapper
  #componentWrapper
  class="autocomplete-wrapper"
  (lostFocus)="handleFocusOut($event)"
  [errors]="errors">
  <input
    luxFlexFill
    #input
    searchInput
    clSelectSelf
    [value]="displayValue"
    (keydown.arrowDown)="onArrowDown($event)"
    (keydown.arrowUp)="onArrowUp($event)"
    (keydown.tab)="onTabDown($event)"
    class="spot-form__input"
    spellcheck="false"
    autocomplete="new-item" />
  <div searchResults [hidden]="!displayedData?.length" class="search-results-wrapper">
    <button
      luxLayout="row"
      class="result-list-item"
      data-cy="autocomplete-item"
      type="button"
      (click)="handleClick($event, item)"
      *ngFor="let item of displayedData; let index = index; let first = first"
      [class.selection]="first">
      <span luxLayoutAlign="start" luxFlex="65%" *ngIf="index <= displayCnt" class="content">{{ item.label }}</span>
    </button>
  </div>
</cl-field-wrapper>
