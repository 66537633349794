<div (focusout)="handleFocusOut($event)" #componentWrapper>
  <label [class.has-errors]="currentErrors?.length" class="spot-form__label" *ngIf="label" tabindex="-1">
    <abbr
      *ngIf="required"
      class="spot-form__label-required-indicator"
      title="This field is required."
      aria-hidden="true"
      >*</abbr
    >
    <span *ngIf="required" class="spot--screenreader-only"> Required Field. </span>
    {{ label }}
  </label>
  <div class="cl-field-wrapper" [class.cl-required]="required" [class.spot-form--error]="currentErrors?.length">
    <ng-content></ng-content>
    <span *ngIf="loading || adder || searcher" class="spot-form__select-open-indicator">
      <svg *ngIf="loading" class="spot-icon spot-loading-spinner" aria-labelledby="title">
        <title></title>
        <use xlink:href="/assets/icons/spot_icons.svg#spinner"></use>
      </svg>
      <svg *ngIf="!loading && searcher" class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
        <title></title>
        <use xlink:href="/assets/icons/spot_icons.svg#search"></use>
      </svg>
      <svg *ngIf="!loading && adder && !searcher" class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
        <title></title>
        <use xlink:href="/assets/icons/spot_icons.svg#add"></use>
      </svg>
    </span>
  </div>
  <div *ngIf="currentErrors?.length" class="spot-form__field-error">
    <div *ngFor="let error of currentErrors; first as isFirst" class="spot-form__field-error-text">
      <div *ngIf="isFirst && error.args">
        {{ error.value | translate: error.args }}
      </div>
      <div *ngIf="isFirst && !error.args">{{ error.value | translate }}</div>
    </div>
  </div>
  <div luxFlexFill *ngIf="microText.length" class="spot-form__microcopy" [ngStyle]="currentErrors.length && { marginTop: '20px' }">
    <ng-container *ngFor="let txt of microText">
      <div class="cl-microcopy" *ngIf="txt">
        {{ txt | translate }}
      </div>
    </ng-container>
  </div>
</div>
