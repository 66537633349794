<app-global-error-handler
        (closed)="handleSnackBarErrorClose()"
        (refresh)="handleAppRefresh()"
        [loggedin]="loggedIn"
        luxLayout="row"></app-global-error-handler>

<div [hidden]="loading" class="spot-typography__text--body app-viewport">
    <div luxLayout="row">
        <lux-header
            #header
            [accession]="accession"
            [env]="environment"
            [configuration]="headerConfiguration"
            [availableLabs]="labs"
            [currentLab]="selectedLab"
            [user]="currentUser"
            [context]="'ORDER_ENTRY'"
            (updateLab)="updateLab($event)"></lux-header>
    </div>
    <div *ngIf="labs.length">
        <div id="flyout-navigation" *ngIf="flyoutReady">
            <lux-flyout
                    luxFill
                    #flyout
                    (flyoutItemSelected)="triggerNavigation()"
                    (helpModalOpen)="helpModalOpen()"
                    [navigationItems]="navigationItems">
            </lux-flyout>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>

<!--- INITIAL APP LOADING STATE -->
<div *ngIf="loading" id="loader" class="spot-long-form-text app-viewport">
    <svg class="spot-icon spot-loading-spinner spot-loading-spinner--large" aria-labelledby="title">
        <title>spinner</title>
        <use xlink:href="/assets/icons/spot_icons.svg#spinner"></use>
    </svg>
</div>
<!--- /INITIAL APP LOADING STATE -->
<cl-help-modal #helpModalContainer></cl-help-modal>

<lux-modal-container-outlet></lux-modal-container-outlet>
