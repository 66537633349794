import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ROUTES } from './app.router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { OrderEntryModule } from './order-entry/order-entry.module';
import { ComponentsModule } from './shared/components/components.module';
import { AppService } from './app.service';
import {
  GlobalErrorHandlerModule,
  GlobalErrorHandlerService,
  SnackbarModule,
  LabRequestInterceptor,
  PendingRequestInterceptor,
  FlyoutModule,
  FeaturesModule,
  ModalContainerModule,
  KeyboardHelpCategoryModule,
  LUX,
  LuxLayoutModule,
} from '@lims-common-ux/lux';
import {
  MsalBroadcastService,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { InteractionStatus, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { UnavailableComponent } from './unavailable/unavailable.component';
import { ApplicationInitService } from './application-init.service';
import { StaticAppData } from './interfaces/application-data.interface';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { filter, take, Observable } from 'rxjs';

declare let CLIENT_ID: string;
declare let TENANT_ID: string;
declare let PROTECTED_RESOURCES: [string, string[]][];

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../i18n/', '.json');
}

export const STATIC_APP_DATA_TOKEN = new InjectionToken<StaticAppData>('StaticAppData');

function securityInitializer(broadcaster: MsalBroadcastService, service: MsalService): () => Observable<any> {
  return () => {
    // this is required to the service to complete its initialization. We need this to be done in an app initializer
    // because we often have data we need to retrieve that is protected, and this will take care of making sure those
    // calls are properly authenticated.
    service.handleRedirectObservable().subscribe(() => {
      // we don't care about the result value here, just let the library do its thing
    });

    return broadcaster.inProgress$.pipe(
      filter((progress) => progress === InteractionStatus.None),
      take(1) // the observable never completes normally, and we only need this to be hit once
    );
  };
}

const PROTECTED = new Map<string, Array<string>>(PROTECTED_RESOURCES);

export function applicationDataInitFactory(service: ApplicationInitService): () => Promise<StaticAppData> {
  return (): Promise<StaticAppData> => service.initialize();
}

function staticDataFactory(service: ApplicationInitService): () => StaticAppData {
  return (): StaticAppData => service.staticAppData;
}

@NgModule({
  declarations: [AppComponent, UnavailableComponent, HelpModalComponent],
  imports: [
    BrowserModule,
    ComponentsModule,
    LuxLayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: CLIENT_ID, // Application (client) ID from the app registration
          authority: 'https://login.microsoftonline.com/' + TENANT_ID + '/',
          redirectUri: '/',
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(PROTECTED),
      }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
    }),
    OrderEntryModule,
    FeaturesModule,
    SnackbarModule,
    GlobalErrorHandlerModule,
    ModalContainerModule,
    FlyoutModule,
    KeyboardHelpCategoryModule,
    LUX,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: securityInitializer,
      deps: [MsalBroadcastService, MsalService],
      multi: true,
    },
    { provide: 'Window', useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: applicationDataInitFactory,
      deps: [ApplicationInitService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendingRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LabRequestInterceptor,
      multi: true,
    },
    {
      provide: STATIC_APP_DATA_TOKEN,
      useFactory: staticDataFactory,
    },
    TranslatePipe,
    AppService,
    GlobalErrorHandlerService,
    {
      provide: ErrorHandler,
      useExisting: GlobalErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: [AppService],
    };
  }
}
