import { PatientAge } from "@lims-common-ux/lux";
import { TranslateService } from "@ngx-translate/core";

type AgeKey = ("years" | "months" | "weeks" | "days");

interface AgeSegment {
  ageKey: AgeKey
  label: string;
}

type AgeLabels = {
  [key in AgeKey]: AgeSegment;
};

export class PatientAgeDOB {
  age: PatientAge;
  dateOfBirth: string;
  translateService;
  // used to manage the i18n needs of PatientAge display
  labels: AgeLabels = {
    years: {
      ageKey: 'years',
      label: 'AGE.Y'
    },
    months: {
      ageKey: 'months',
      label: 'AGE.M'
    },
    weeks: {
      ageKey: 'weeks',
      label: 'AGE.W'
    },
    days: {
      ageKey: 'days',
      label: 'AGE.D'
    }
  };

  constructor(
    age: PatientAge,
    dateOfBirth: string,
    translateService: TranslateService
  ) {
    this.age = age;
    this.dateOfBirth = dateOfBirth;
    this.translateService = translateService;
  }

  segmentArray(): AgeSegment[] {
    return [this.labels.years, this.labels.months, this.labels.weeks, this.labels.days];
  }

  ageSegmentLabels(): string[] {
    const years = this.translateService.instant(this.labels.years.label);
    const months = this.translateService.instant(this.labels.months.label);
    const weeks = this.translateService.instant(this.labels.weeks.label);
    const days = this.translateService.instant(this.labels.days.label);

    const labels = [years, months, weeks, days];

    return labels;
  }

  getAgeKeysWithValues(): AgeKey[] {
    const keys = [];

    if (this.age) {
      Object.keys(this.age).forEach((key) => {
        if (this.age[key]) {
          keys.push(key);
        }
      });
    }
    return keys;
  }

  createPatientAge() {
    return {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0
    }
  }

  getAgeKeysWithoutValues(): AgeKey[] {
    const keys = [];

    if (!this.age) {
      this.age = this.createPatientAge();
    }

    Object.keys(this.age).forEach((key) => {
      if (!this.age[key]) {
        keys.push(key);
      }
    });

    return keys;
  }

  getAgeKeyFromLabel(label: string): AgeKey {
    let keyFromLabel;

    Object.keys(this.labels).forEach((key) => {
      if (this.translateService.instant(this.labels[key].label) === label) {
        keyFromLabel = this.labels[key].ageKey;
      }
    });

    return keyFromLabel;
  }

  updateAge(ageKey: string, ageVal: number): void {
    if (!ageVal || ageVal < 0) {
      ageVal = 0;
    }

    if (!this.age) {
      this.age = this.createPatientAge();
    }

    this.age[ageKey] = ageVal;
  }
}
