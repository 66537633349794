<div class="sample-attributes" *ngIf="sample.supportedAttributes?.length">
  <div class="sample-attributes-trigger-wrapper">
    <a
      #sampleAttributesTrigger
      *ngIf="showEditTrigger"
      data-cy="sample-attributes-edit-trigger"
      class="sample-attributes-edit-trigger"
      href="javascript:void(0)"
      (keydown.enter)="toggleShowAttributes($event)"
      (click)="toggleShowAttributes($event)">
      <svg class="spot-icon" aria-labelledby="title">
        <title></title>
        <use xlink:href="/assets/icons/spot_icons.svg#edit"></use>
      </svg>
    </a>

    <div
      *ngIf="!showEditTrigger"
      (click)="toggleShowAttributes($event)"
      data-cy="new-sample-attributes-trigger"
      class="new-sample-attributes-trigger">
      <svg class="spot-icon" aria-labelledby="title">
        <title></title>
        <use xlink:href="/assets/icons/spot_icons.svg#double-right"></use>
      </svg>
    </div>
  </div>

  <div
    class="sample-attributes-wrapper"
    #sampleAttributesWrapper
    data-cy="sample-attributes-wrapper"
    *ngIf="showAttributes"
    (mouseenter)="handleFocusIn()"
    (mouseleave)="handleFocusOut()"
    (focusin)="handleFocusIn()"
    (focusout)="handleFocusOut()"
    (keydown.escape)="toggleHideAttributes($event)"
    (keydown.enter)="assignAttributes()">
    <div class="sample-attributes-container">
      <ul class="sample-attributes-column" *ngFor="let attributeType of sample.supportedAttributes; index as i">
        <li class="sample-attributes-item" *ngFor="let attribute of sampleAttributesByType[attributeType]; index as j">
          <label
            [id]="sampleAttributeIdPrefix + i + '_' + j"
            class="spot-form__radio"
            [attr.data-cy]="attribute.value"
            [class]="isSelected(attribute) ? 'selected' : ''"
            (click)="toggleAttributeSelection(attribute, $event)"
            (keydown.arrowLeft)="handleArrowLeft($event, i)"
            (keydown.arrowRight)="handleArrowRight($event, i)"
            (keydown.arrowUp)="handleArrowUp($event, i, j)"
            (keydown.arrowDown)="handleArrowDown($event, i, j)"
            (keydown.shift.tab)="handleArrowUp($event, i, j)"
            (keydown.tab)="handleArrowDown($event, i, j)"
            (keydown.space)="toggleAttributeSelection(attribute, $event)">
            <input
              type="radio"
              class="spot-form__radio-input"
              id="{{ sampleAttributeIdPrefix + attribute.value }}"
              [checked]="isSelected(attribute)"
              name="{{ attributeType }}"
              value="{{ attribute.value }}" />
            <span class="spot-form__radio-inner">
              <span class="spot-form__radio-visual"></span>
              <span class="spot-form__radio-label">{{ attribute.name }}</span>
            </span>
          </label>
        </li>
      </ul>
      <ul class="sample-attributes-column draw-time-column">
        <li><cl-sample-draw-time
          #drawTime
          (confirm)="assignAttributes()"
          (arrowDown)="handleDrawTimeArrowDown()"
          (arrowUp)="handleDrawTimeArrowUp()"
          (arrowLeft)="handleDrawTimeArrowLeft()"
          (arrowRight)="handleDrawTimeArrowRight()"
          [(ngModel)]="drawTimeValue"
          (ngModelChange)="handleDrawTimeChange($event)"></cl-sample-draw-time></li>
      </ul>
    </div>
  </div>
</div>
